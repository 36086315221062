var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Validation states"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeValidation) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("When you default to a ")]), _c('code', [_vm._v("null")]), _c('span', [_vm._v(" value, and the user has not selected a value, you can use the ")]), _c('code', [_vm._v("state")]), _c('span', [_vm._v(" prop to apply one of the contextual validation styles to the component.")])]), _c('b-form-spinbutton', {
    attrs: {
      "id": "sb-inline",
      "state": Boolean(_vm.value >= 50)
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }