var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Min and Max"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeMinMax) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Spinbuttons have a default range from")]), _c('code', [_vm._v("1")]), _c('span', [_vm._v(" to")]), _c('code', [_vm._v("100")]), _vm._v(", which can be changed by setting the "), _c('code', [_vm._v("min")]), _c('span', [_vm._v(" and ")]), _c('code', [_vm._v("max")]), _c('span', [_vm._v(" props.")])]), _c('label', {
    attrs: {
      "for": "sb-maxmin"
    }
  }, [_vm._v("Spin button with min 0 and max 10")]), _c('b-form-spinbutton', {
    attrs: {
      "id": "sb-maxmin",
      "min": "0",
      "max": "10"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }