var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Locale"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeLocale) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("By default ")]), _c('code', [_vm._v("<b-form-spinbutton>")]), _c('span', [_vm._v(" will format the displayed number in the users browser default locale. You can change the localized formatting by specifying a locale (or array of locales) via the ")]), _c('code', [_vm._v("locale")]), _c('span', [_vm._v(" prop. ")])]), _c('div', [_c('label', {
    attrs: {
      "for": "sb-locales"
    }
  }, [_vm._v("Locale")]), _c('b-form-select', {
    attrs: {
      "id": "sb-locales",
      "options": _vm.locales
    },
    model: {
      value: _vm.locale,
      callback: function callback($$v) {
        _vm.locale = $$v;
      },
      expression: "locale"
    }
  }), _c('label', {
    staticClass: "mt-2",
    attrs: {
      "for": "sb-local"
    }
  }, [_vm._v("Spin button with locale")]), _c('b-form-spinbutton', {
    attrs: {
      "id": "sb-locale",
      "locale": _vm.locale,
      "min": "0",
      "max": "10",
      "step": "0.125"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c('b-card-text', {
    staticClass: "mt-1"
  }, [_vm._v(" Value: " + _vm._s(_vm.value) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }