var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Number wrapping"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeWrap) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To allow the spin button to wrap from max to min when incrementing (or min to max when decrementing), set the ")]), _c('code', [_vm._v("wrap")]), _c('span', [_vm._v(" prop to ")]), _c('code', [_vm._v("true")]), _c('span', [_vm._v(".")])]), _c('label', {
    attrs: {
      "for": "sb-wrap"
    }
  }, [_vm._v("Wrapping value spin button")]), _c('b-form-spinbutton', {
    attrs: {
      "id": "sb-wrap",
      "wrap": "",
      "min": "1",
      "max": "25",
      "placeholder": "--"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }