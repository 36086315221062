var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Size"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeSize) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("As with other form controls, ")]), _c('code', [_vm._v("<b-form-spinbutton>")]), _c('span', [_vm._v(" supports small and large sizing via setting the ")]), _c('code', [_vm._v("size")]), _c('span', [_vm._v(" prop to either ")]), _c('code', [_vm._v("'sm'")]), _c('span', [_vm._v(" or ")]), _c('code', [_vm._v("'lg'")]), _c('span', [_vm._v(", respectively.")])]), _c('div', [_c('label', {
    attrs: {
      "for": "sb-small"
    }
  }, [_vm._v("Spin button - Small size")]), _c('b-form-spinbutton', {
    staticClass: "mb-1",
    attrs: {
      "id": "sb-small",
      "size": "sm",
      "placeholder": "--"
    }
  }), _c('label', {
    attrs: {
      "for": "sb-default"
    }
  }, [_vm._v("Spin button - Default size")]), _c('b-form-spinbutton', {
    staticClass: "mb-1",
    attrs: {
      "id": "sb-default",
      "placeholder": "--"
    }
  }), _c('label', {
    attrs: {
      "for": "sb-large"
    }
  }, [_vm._v("Spin button - Large size")]), _c('b-form-spinbutton', {
    staticClass: "mb-1",
    attrs: {
      "id": "sb-large",
      "size": "lg",
      "placeholder": "--"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }