var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Disabled and readonly states"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeDisabledReadonly) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Setting the prop ")]), _c('code', [_vm._v("disabled")]), _c('span', [_vm._v(" places the component in a disabled, non-interactive state. The ")]), _c('code', [_vm._v("readonly")]), _c('span', [_vm._v(" prop places the component in a readonly state (focusable, but the value cannot be changed by the user). ")])]), _c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('label', {
    attrs: {
      "for": "sb-disabled"
    }
  }, [_vm._v("Disabled spin button")]), _c('b-form-spinbutton', {
    attrs: {
      "id": "sb-disabled",
      "disabled": ""
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('label', {
    attrs: {
      "for": "sb-readonly"
    }
  }, [_vm._v("Readonly spin button")]), _c('b-form-spinbutton', {
    attrs: {
      "id": "sb-readonly",
      "readonly": ""
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }