var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Step"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeStep) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("The default step increment is ")]), _c('code', [_vm._v("1")]), _c('span', [_vm._v(", and can be changed via the ")]), _c('code', [_vm._v("step")]), _c('span', [_vm._v("prop (decimal values allowed).")]), _c('span', [_vm._v("When ")]), _c('code', [_vm._v("step")]), _c('span', [_vm._v(" is set, the value will always be a multiple of the step size plus the minimum value.")])]), _c('label', {
    attrs: {
      "for": "sb-step"
    }
  }, [_vm._v("Spin button with step of 0.25")]), _c('b-form-spinbutton', {
    attrs: {
      "id": "sb-step",
      "step": "0.25"
    },
    model: {
      value: _vm.value1,
      callback: function callback($$v) {
        _vm.value1 = $$v;
      },
      expression: "value1"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }