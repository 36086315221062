var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Vertical"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeVertical) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v("Spinbuttons can be oriented in vertical mode:")]), _c('label', {
    attrs: {
      "for": "sb-vertical"
    }
  }, [_vm._v("Vertical spin button")]), _c('br'), _c('b-form-spinbutton', {
    attrs: {
      "id": "sb-vertical",
      "vertical": ""
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }