var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Basic Spinbutton"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v(" Spin buttons allow for incrementing or decrementing a numerical value within a range of a minimum and maximum number, with optional step value. ")]), _c('div', {
    staticClass: "mt-2"
  }, [_c('label', {
    attrs: {
      "for": "demo-sb"
    }
  }, [_vm._v("Spin Button")]), _c('b-form-spinbutton', {
    attrs: {
      "id": "demo-sb",
      "min": "1",
      "max": "100"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c('b-card-text', {
    staticClass: "mt-1"
  }, [_vm._v(" Value: " + _vm._s(_vm.value) + " ")]), _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "show": "",
      "variant": "success"
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" The ArrowUp and ArrowDown keys can be used to increment or decrement the value. ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }