var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Width"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeWidth) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("The control (when not ")]), _c('code', [_vm._v("vertical")]), _c('span', [_vm._v(" or ")]), _c('code', [_vm._v("inline")]), _c('span', [_vm._v(") will expand to the maximum width of the parent container You can control width via utility classes such as ")]), _c('code', [_vm._v("w-50, w-75, w-100")]), _c('span', [_vm._v(", or use styles to set the width.")])]), _c('b-form-group', [_c('b-form-spinbutton', {
    staticClass: "w-100",
    attrs: {
      "min": "0",
      "max": "10",
      "step": "0.125"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1), _c('b-form-group', [_c('b-form-spinbutton', {
    staticClass: "w-75",
    attrs: {
      "min": "0",
      "max": "10",
      "step": "0.125"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1), _c('b-form-group', [_c('b-form-spinbutton', {
    staticClass: "w-50",
    attrs: {
      "min": "0",
      "max": "10",
      "step": "0.125"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }