var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Inline"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeInline) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("The spin button will automatically adjust it's width to fit the displayed value. Use the prop ")]), _c('code', [_vm._v("inline")])]), _c('div', [_c('label', {
    staticClass: "mr-1",
    attrs: {
      "for": "sb-inline"
    }
  }, [_vm._v("Inline spin button")]), _c('b-form-spinbutton', {
    attrs: {
      "id": "sb-inline",
      "inline": ""
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }